.landing {
    padding-top: 200px;
    height: 100vh;
}

.fade-in {
    -webkit-animation: fadein 2s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s;
    /* Firefox < 16 */
    -ms-animation: fadein 2s;
    /* Internet Explorer */
    -o-animation: fadein 2s;
    /* Opera < 12.1 */
    animation: fadein 2s;
}

#landing-intro {
    font-size: medium;
}

#landing-name {
    font-size: xxx-large;
    font-weight: bold;
}

#landing-building {
    font-size: xx-large;
    font-weight: 400;
}

#landing-description {
    font-size: large;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#chevron-down {
    text-align: center;
}