.header-choice {
    list-style-type: none;
    display: inline;
    padding: 0 5px;
}
a {
    text-decoration: none;
}
a:visited {
    color: blue;
}
#header {
    position: absolute;
    right: 50px;
    top: 0;
}